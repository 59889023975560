import { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } from './application.constants';

export default class CommonUtils {
  /**
   * Verify if its mobile viewport
   */
  static isOnlyMobileView(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    // On windows machine its was breaking on 767 to fix this added extra 0.5
    return window.matchMedia(`(max-width: ${BREAKPOINT_TABLET + 0.5}px)`).matches;
  }

  /**
   * Verify if its tablet viewport
   */
  static isTabletView(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return window.matchMedia(`(max-width: ${BREAKPOINT_DESKTOP}px) and (min-width: ${BREAKPOINT_TABLET}px)`).matches;
  }
}
