import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

export interface cobrandStore {
  cobrandData: {
    activeTabValue: string;
  };
  setCobrandData: (data: { activeTabValue: string }) => void;
}

const initialState: cobrandStore = {
  cobrandData: {
    activeTabValue: 'personal',
  },
  setCobrandData: () => null,
};

const CobrandStore: StateCreator<cobrandStore> = set => {
  return {
    ...initialState,
    setCobrandData: data => {
      set({
        cobrandData: { ...data },
      });
    },
  };
};

export const useStore = createAppStore(CobrandStore, {
  usePersistentStore: false,
});
