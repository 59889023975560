// Imports for external libraries go here.
import React, { FC } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { RichText, Button, Image } from '@marriott/mi-ui-library';
import { CardOverviewProps } from './CardOverview.types';
import { StyledCardOverview } from './CardOverview.styles';
import { CardOverviewHorizontal } from '../../molecules/CardOverviewHorizontal';
import { useStore } from '../../modules/store/cobrandStore';
import { shouldCardDisplay } from '../../modules/utils';
import { CardArtBadge } from '../../molecules/CardArtBadge';
import { CARD_LAYOUT_TYPE, CHARACTER_COUNT, COBRAND_CARD_TYPE } from '../../modules/constants/constants';
export const CardOverviewConfig = {
  emptyLabel: 'CardOverview',
  isEmpty: () => true,
  resourceType: `/components/content/cardoverview`,
};

export const CardOverviewComp: FC<CardOverviewProps> = (props: CardOverviewProps) => {
  const {
    cardType,
    imagePath,
    cardArtIcon,
    cardArtText,
    displayCardArtIcon,
    altText,
    header,
    pointSectionSeparator,
    pointSectionDescription,
    cardSectionSeparator,
    cardSectionDescription,
    linkText,
    linkUrl,
    openInaNewTab,
    additionalTrackingVariables,
    trackingDescription,
    impressionTrack,
    trackingContentPosition,
    atCCeVar48,
    clickTrack,
    merchandisingCategory,
    impressionCount,
    trackingTag,
    trackingOfferType,
    componentId,
    appliedCssClassNames,
    customClass,
    styleclass,
    variation,
  } = props;

  const { cobrandData } = useStore(store => store);

  const trackingProperties = {
    trackingContentPosition,
    trackingDescription,
    trackingOfferType,
    trackingTag,
    merchandisingCategory,
    atCCeVar48,
    additionalTrackingVariables,
    clickTrack: clickTrack === 'true',
    impressionTrack: impressionTrack === 'true',
    impressionCount: impressionCount === 'true',
    isCoBrand: true,
  };

  const linkType = openInaNewTab ? 'external' : 'internal';
  const clickTrackValue = `${trackingContentPosition}|${trackingDescription}|${linkType}|${additionalTrackingVariables}`;
  const getCssClass = (variation: string, cardType: string) => {
    return variation === CARD_LAYOUT_TYPE.VERTICALLAYOUT
      ? 'card-overview-foc-image'
      : `card-overview-blp-image ${
          cardType === COBRAND_CARD_TYPE.BUSINESSCARDTYPE ? 'card-overview-blp-image--margin' : ''
        }`;
  };
  return variation === 'horizontal-card-layout' ? (
    <CardOverviewHorizontal data-testid="cardoverview" data-component-name="o-cobrand-cardoverview" {...props} />
  ) : (
    <StyledCardOverview
      data-testid="cardoverview"
      data-component-name="o-cobrand-cardoverview"
      id={componentId}
      className={clsx(
        'card-overview justify-content-center d-md-flex',
        variation === 'vertical-card-layout' ? 'col-12 p-0' : '',
        shouldCardDisplay(cardType, cobrandData.activeTabValue) ? 'd-flex' : 'd-none',
        `${appliedCssClassNames ? appliedCssClassNames : ''}`
      )}
      data-cardtype={cardType}
    >
      <div className={clsx(variation === 'vertical-card-layout' ? 'card-overview-foc-wrapper' : '', 'd-flex')}>
        <div
          className={clsx(
            'pt-5 pt-md-0 card-overview-wrapper',
            variation === 'vertical-card-layout' ? 'mt-5' : '',
            'justify-content-center d-flex'
          )}
        >
          <div className={clsx('d-flex flex-grow-1 justify-content-center', 'mt-0 pt-0 mt-md-5 pt-md-5')}>
            <div
              className={clsx(
                variation === 'vertical-card-layout'
                  ? 'px-3 pt-2 pt-md-0'
                  : 'mx-lg-1 card-overview-bordered__default px-md-3 px-2',
                `card-overview-bordered text-center pb-4 `,
                'd-flex flex-column justify-content-between',
                pointSectionSeparator || cardSectionSeparator === 'true' ? 'card-overview-seperator' : '',
                't-background-color',
                customClass,
                styleclass
              )}
            >
              <div className={clsx('d-flex flex-column flex-grow-1')}>
                <div
                  className={clsx(
                    variation === 'vertical-card-layout' ? 'px-0 card-overview-bordered__image mx-auto' : '',
                    'px-lg-0'
                  )}
                >
                  {displayCardArtIcon ? (
                    <CardArtBadge
                      imagePath={imagePath}
                      imageClass={'col-12 p-0'}
                      badgeClass={'mx-2'}
                      defaultImageText={altText}
                      badgeDetails={`<div class='d-flex align-items-center justify-content-center badge-txt-align'>
                          <div><span class="${cardArtIcon && `${cardArtIcon.split(':')[1]} m-0 pr-1`}"></span></div>
                          <div class='t-font-xs t-overline-overlay'>${cardArtText?.toUpperCase()}</div>
                            </div>`}
                      customClass={clsx(getCssClass(variation || '', COBRAND_CARD_TYPE.BUSINESSCARDTYPE))}
                    />
                  ) : (
                    <Image
                      defaultImageURL={imagePath}
                      altText={altText}
                      customClass={clsx(getCssClass(variation || '', COBRAND_CARD_TYPE.BUSINESSCARDTYPE))}
                    ></Image>
                  )}
                </div>
                {header && header.length && (
                  <RichText
                    componentId={`header-${componentId}`}
                    text={header}
                    customClass={clsx(
                      'mx-auto mt-3',
                      variation === 'vertical-card-layout' ? 'card-overview-foc-header' : 'card-overview-header'
                    )}
                  />
                )}
                {pointSectionSeparator === 'true' && <hr className={clsx('mb-3 mx-0 row')} />}
                {pointSectionDescription && pointSectionDescription.length && (
                  <RichText
                    componentId={`pointsSection-${componentId}`}
                    text={pointSectionDescription}
                    customClass={clsx(
                      'd-flex flex-column flex-grow-1',
                      'card-overview-subheader',
                      variation === 'vertical-card-layout' ? 'card-overview-foc' : ''
                    )}
                  />
                )}
                {cardSectionSeparator === 'true' && <hr className={clsx('mt-3 mb-4 mx-0 row')} />}
                {cardSectionDescription && cardSectionDescription.length && (
                  <RichText
                    componentId={`cardSection-${componentId}`}
                    text={cardSectionDescription}
                    customClass={clsx('d-flex flex-column flex-grow-1')}
                  />
                )}
              </div>
              <div>
                <Button
                  isLink={true}
                  href={linkUrl}
                  target={openInaNewTab === 'true' ? '_blank' : '_self'}
                  /* "track-completed" class is added to escape getNonCoBrandTrackValue method in clientlib.js */
                  className={clsx(
                    variation === 'vertical-card-layout' ? 'mt-2 mt-lg-0' : '',
                    'm-button-m mt-md-3 mb-0 mt-0',
                    'track-completed'
                  )}
                  trackingProperties={trackingProperties}
                  buttonCopy={linkText}
                  linkType={linkType}
                  custom_click_track_value={clickTrackValue}
                  linkAriaLabelOpenNewWindow={openInaNewTab ? 'Open in new tab' : ''}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledCardOverview>
  );
};
export const CardOverview = (props: CardOverviewProps) => {
  return (
    <EditableComponent config={CardOverviewConfig} {...props}>
      <CardOverviewComp {...props} />
    </EditableComponent>
  );
};
